<template>
  <div>
    <v-tooltip v-for="item in variables" :key="item.value" bottom>
      <template v-slot:activator="{ on: on, attrs: attrs }">
        <v-btn
          @mousedown="addVariable(item)"
          depressed
          color="pink"
          outlined
          small
          class="me-2 mb-2 px-1"
          v-on="on"
          v-bind="attrs"
        >
          {{ item.label }}
        </v-btn>
      </template>

      {{ item.description }}
    </v-tooltip>
  </div>
</template>

<script>
import { notificationTemplateVariables } from "@/core/data/options";

export default {
  name: "VariableCloud",
  props: {
    systemId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  computed: {
    variables() {
      const variables = [];

      for (const index in notificationTemplateVariables) {
        const item = notificationTemplateVariables[index];

        if (item.alwaysAvailable) {
          variables.push(item);
        } else if (this.systemId) {
          if (item.availableIn.indexOf(this.systemId) !== -1) {
            variables.push(item);
          }
        }
      }

      return variables;
    },
  },
  methods: {
    addVariable(item) {
      this.$emit("select", item);
    },
  },
};
</script>
